// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require.context('../images', true, /\.(gif|jpg|png|svg)$/i)
require.context('../fonts', true, /\.(ttf|woff|woff2|svg)$/i)

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import "@stylesheets/site.scss";
//import "babel-polyfill";

import header from '@script/blocks/header';
import modal from '@script/blocks/modal';
import mainEvent from '@script/blocks/main-event';
import mainBanner from '@script/blocks/main-banner';
import ticker from '@script/blocks/ticker';
import specialEvent from '@script/blocks/special-event';
import profile from '@script/blocks/profile';
import scrollto from '@script/blocks/scrollto';
import repertoire from '@script/blocks/repertoire';
import onscrollAnimation from '@script/blocks/onscroll-animation';
import filter from '@script/blocks/filter';
import calendar from '@script/blocks/calendar';
import poster from '@script/blocks/poster';
import search from '@script/blocks/search';
import news from '@script/blocks/news';
import posterItem from '@script/blocks/poster-item';
import contacts from '@script/blocks/contacts';
import ticketDate from '@script/blocks/ticket-date';
import ticketTip from '@script/blocks/ticket-tip';
import cookiePane from '@script/blocks/cookie-pane';
import department from '@script/blocks/department';

import '@script/blocks/map';

import '@script/avilkin/blind';
import '@script/avilkin/avilkin';
import avpopup from '@script/avilkin/avpopup';

const application = Application.start();
const context = require.context("@script/controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

header();
search();
modal();
mainEvent();
mainBanner();
ticker();
specialEvent();
profile();
scrollto();
repertoire();
onscrollAnimation();
filter();
calendar();
poster();
news();
posterItem();
contacts();
ticketDate();
ticketTip();
cookiePane();
department();
avpopup();
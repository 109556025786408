// селекторы для создания popup'ов
// const selectors = '[data-popup], .perfomance__image>img, .swiper-slide>img, .profile__img>img';
const selectors = '[data-avpopup]';

// дефолтная картинка для попапа
// const defimage = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxNTAnIGhlaWdodD0nMTUwJyB2aWV3Qm94PScwIDAgMjQgMjQnPjxnIGZpbGw9J25vbmUnIHN0cm9rZT0nY3VycmVudENvbG9yJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMic+PHBhdGggZD0nTTIgNmE0IDQgMCAwIDEgNC00aDEyYTQgNCAwIDAgMSA0IDR2MTJhNCA0IDAgMCAxLTQgNEg2YTQgNCAwIDAgMS00LTR6Jy8+PGNpcmNsZSBjeD0nOC41JyBjeT0nOC41JyByPScyLjUnLz48cGF0aCBkPSdNMTQuNTI2IDEyLjYyMUw2IDIyaDEyLjEzM0EzLjg2NyAzLjg2NyAwIDAgMCAyMiAxOC4xMzNWMThjMC0uNDY2LS4xNzUtLjY0NS0uNDktLjk5bC00LjAzLTQuMzk1YTIgMiAwIDAgMC0yLjk1NC4wMDYnLz48L2c+PC9zdmc+';
// const defimage = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzMDAgMzAwJz48bGluZWFyR3JhZGllbnQgaWQ9J2ExMSc+PHN0b3Agb2Zmc2V0PScwJyBzdG9wLWNvbG9yPScjMkQyRDJCJz48L3N0b3A+PHN0b3Agb2Zmc2V0PSc1JyBzdG9wLWNvbG9yPScjMkQyRDJCJyBzdG9wLW9wYWNpdHk9JzAnPjwvc3RvcD48L2xpbmVhckdyYWRpZW50PjxjaXJjbGUgZmlsbD0nbm9uZScgc3Ryb2tlPSd1cmwoI2ExMSknIHN0cm9rZS13aWR0aD0nOCcgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtZGFzaGFycmF5PScwIDE1IDAgMTUgMCAxNSAwIDE1IDAgMTUgMCAxNSAwIDM2MCcgY3g9JzE1MCcgY3k9JzE1MCcgcj0nMjUnIHRyYW5zZm9ybS1vcmlnaW49J2NlbnRlcic+PGFuaW1hdGVUcmFuc2Zvcm0gdHlwZT0ncm90YXRlJyBhdHRyaWJ1dGVOYW1lPSd0cmFuc2Zvcm0nIGNhbGNNb2RlPSdzcGxpbmUnIGR1cj0nMicgdmFsdWVzPScwOzM2MCcga2V5VGltZXM9JzA7MScga2V5U3BsaW5lcz0nMCAwIDEgMScgcmVwZWF0Q291bnQ9J2luZGVmaW5pdGUnPjwvYW5pbWF0ZVRyYW5zZm9ybT48L2NpcmNsZT48L3N2Zz4=';
const defimage = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzMDAgMzAwJz48bGluZWFyR3JhZGllbnQgaWQ9J2ExMSc+PHN0b3Agb2Zmc2V0PScwJyBzdG9wLWNvbG9yPScjZWZlYmUxJz48L3N0b3A+PHN0b3Agb2Zmc2V0PSc1JyBzdG9wLWNvbG9yPScjZWZlYmUxJyBzdG9wLW9wYWNpdHk9JzAnPjwvc3RvcD48L2xpbmVhckdyYWRpZW50PjxjaXJjbGUgZmlsbD0nbm9uZScgc3Ryb2tlPSd1cmwoI2ExMSknIHN0cm9rZS13aWR0aD0nOCcgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtZGFzaGFycmF5PScwIDE1IDAgMTUgMCAxNSAwIDE1IDAgMTUgMCAxNSAwIDM2MCcgY3g9JzE1MCcgY3k9JzE1MCcgcj0nMjUnIHRyYW5zZm9ybS1vcmlnaW49J2NlbnRlcic+PGFuaW1hdGVUcmFuc2Zvcm0gdHlwZT0ncm90YXRlJyBhdHRyaWJ1dGVOYW1lPSd0cmFuc2Zvcm0nIGNhbGNNb2RlPSdzcGxpbmUnIGR1cj0nMicgdmFsdWVzPScwOzM2MCcga2V5VGltZXM9JzA7MScga2V5U3BsaW5lcz0nMCAwIDEgMScgcmVwZWF0Q291bnQ9J2luZGVmaW5pdGUnPjwvYW5pbWF0ZVRyYW5zZm9ybT48L2NpcmNsZT48L3N2Zz4=';
// стиль для черного задника:
const blackscreenstyle = `
    touch-action: manipulation;
    position:fixed;
    overflow: hidden;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,.8);
    z-index:999;
    display:flex;
    justify-content: center;
    align-items: center;
    opacity:0;
    display:none;
  `;
// стиль для фрэйма-рамки:
const innerdivstyle = `
    position:relative;
    // padding:5px;
    // background:rgb(239, 235, 225);
    margin:auto;
    width:auto;
  `;
// стиль для изображения:
const imagestyle = `
    max-width:95vw;
    max-height:95vh;
    opacity: 1;
    transition: opacity .5s, transform .05s;
  `;
// стиль для кнопки закрытия:
const xdivstyle = `
    position:absolute;
    width:30px;
    height:30px;
    right:0;
    top:0;
    background:rgb(239, 235, 225);
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nNDcnIGhlaWdodD0nNDcnIHZpZXdCb3g9JzAgMCA0NyA0NycgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cmVjdCB4PSczNC42NDgyJyB5PScxMS4zMTM3JyB3aWR0aD0nMicgaGVpZ2h0PSczMycgdHJhbnNmb3JtPSdyb3RhdGUoNDUgMzQuNjQ4MiAxMS4zMTM3KScgZmlsbD0nYmxhY2snLz48cmVjdCB4PSczNi4wNjI1JyB5PSczMy45NDEyJyB3aWR0aD0nMicgaGVpZ2h0PSczMycgdHJhbnNmb3JtPSdyb3RhdGUoMTM1IDM2LjA2MjUgMzMuOTQxMiknIGZpbGw9J2JsYWNrJy8+PC9zdmc+Cg==');
    background-size: contain;
    cursor:pointer;
  `;

const popup = document.createElement('div');
popup.className = "av-popup";
popup.style.cssText = blackscreenstyle;
popup.innerHTML = `<div style="${innerdivstyle}"><img src="${defimage}" style="${imagestyle}" /></div><div style="${xdivstyle}"></div>`;
document.body.appendChild(popup);
popup.addEventListener("click", (e) => { e.target == popup && closePopup() });
popup.lastChild.addEventListener("click", closePopup);
const img = popup.querySelector('img');
img.addEventListener("dragstart", (e) => { e.preventDefault(); });
let isScaling = false;
let isDragging = false;
let lastDist = 0;
let dragPosition = { x: 0, y: 0 };
let imgTrans = { scale: 1, transX: 0, transY: 0 };
// использование тачпада
popup.addEventListener("touchstart", (e) => {
    if (e.touches.length === 2) {
        isScaling = true;
        lastDist = Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);
    } else {
        isDragging = true;
        dragPosition.x = e.touches[0].pageX
        dragPosition.y = e.touches[0].pageY;
    }
});
popup.addEventListener("touchmove", (e) => {
    e.preventDefault();
    if (isScaling) {
        let dist = Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);
        let distdiff = dist - lastDist;
        imgTransform(0, 0, (.1 * (distdiff < -.3 ? -1 : (distdiff > .3 ? 1 : 0))));
        lastDist = dist;
    } else if (isDragging && imgTrans.scale > 1) {
        let distleft = e.touches[0].pageY - dragPosition.y; dragPosition.y = e.touches[0].pageY;
        let disttop = e.touches[0].pageX - dragPosition.x; dragPosition.x = e.touches[0].pageX;
        imgTransform(disttop, distleft, 0);
        img.style.cursor = "grabbing";
    }
});
popup.addEventListener("touchend", (e) => {
    isScaling = isDragging = false;
});
// использование мыши
popup.addEventListener("wheel", (e) => {
    e.preventDefault();
    imgTransform(0, 0, (.25 * (e.deltaY > 0 ? -1 : (e.deltaY == 0 ? 0 : 1))));
    setCursor();
});
popup.addEventListener("mousedown", (e) => {
    isDragging = true;
    dragPosition.x = e.screenX;
    dragPosition.y = e.screenY;
});
popup.addEventListener("mousemove", (e) => {
    e.preventDefault();
    if (isDragging && imgTrans.scale > 1) {
        let distleft = e.screenY - dragPosition.y; dragPosition.y = e.screenY;
        let disttop = e.screenX - dragPosition.x; dragPosition.x = e.screenX;
        imgTransform(disttop, distleft, 0);
        setCursor();
    }
});
popup.addEventListener("mouseup", (e) => {
    isDragging = false;
    setCursor();
});

function setCursor() {
    if (imgTrans.scale > 1) {
        if(isDragging){
            img.style.cursor = "grabbing";
        }else{
            img.style.cursor = "grab";
        }
    } else {
        img.style.cursor = "auto";
    }
}


/////////////////////////////////////////////////////////////////////////

function imgTransform(X = 0, Y = 0, scale = 0) {

    scale *= imgTrans.scale / 2; // Поправка
    imgTrans.scale += scale;

    let maxscale = img.naturalWidth / img.offsetWidth * 2; // Максимальное увеличение = двойной размер оригинала

    imgTrans.scale = imgTrans.scale > maxscale ? maxscale : (imgTrans.scale < 1 ? 1 : imgTrans.scale);

    let maxHorizontalTranslate = img.offsetWidth * (imgTrans.scale - 1) / 2 / imgTrans.scale; // максимальное смещение по горизонтали
    let maxVerticalTranslate = img.offsetHeight * (imgTrans.scale - 1) / 2 / imgTrans.scale; // максимальное смещение по вертикали

    imgTrans.transY += Y / imgTrans.scale;
    imgTrans.transY = Math.abs(imgTrans.transY) <= maxVerticalTranslate ? imgTrans.transY : maxVerticalTranslate * Math.sign(imgTrans.transY);

    imgTrans.transX += X / imgTrans.scale;
    imgTrans.transX = Math.abs(imgTrans.transX) <= maxHorizontalTranslate ? imgTrans.transX : maxHorizontalTranslate * Math.sign(imgTrans.transX);

    img.style.transform = `scale(${imgTrans.scale}) translateX(${imgTrans.transX}px) translateY(${imgTrans.transY}px)`;

}

function closePopup() {
    popup.style.opacity = 0, setTimeout(() => { popup.style.display = 'none'; img.src = defimage; imgTrans = { scale: 1, transX: 0, transY: 0 }; imgTransform(0, 0, 0); }, 250);
    document.body.style.touchAction = "pan-x pan-y";
}

function replaceImage(newsrc) {
    img.style.opacity = 0;
    setTimeout(() => {
        img.src = newsrc;
        img.style.opacity = 1;
    }, 1000);
}

function initPopup() {
    [...document.querySelectorAll(selectors)].forEach((el) => {
        let a = document.createElement("a");
        a.style.cssText += "cursor:pointer;";
        el.parentNode.insertBefore(a, el);
        a.appendChild(el);
        a.addEventListener("click", () => {
            document.body.style.touchAction = "none";
            popup.style.display = 'flex';
            popup.style.opacity = 1;
            // "Предзагрузка" изображения
            let newImage = new Image();
            newImage.src = el.dataset.avpopup || el.src;
            newImage.addEventListener('load', (e) => { replaceImage(newImage.src); });
        });
    });
}


export default function () {
    window.addEventListener('DOMContentLoaded', () => {
        setTimeout(initPopup, 500);
    });
}
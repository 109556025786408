import * as Swiper from "swiper/dist/js/swiper.js";

const swiperContainer = document.querySelector('.main-banner .swiper-container');
const swiperWrapper = swiperContainer && swiperContainer.querySelector('.swiper-wrapper')
const swiperSlideCount = swiperWrapper && swiperWrapper.querySelectorAll('.swiper-slide').length

export default function() {
  if(swiperContainer) {
    const mainBannerSwiper = new Swiper(swiperContainer, {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      effect: 'fade',
      cssMode: true,
      slidesPerView: 1,
      speed: 1000,
      autoHeight: true,
      autoplay: swiperSlideCount > 1 ? {delay: 7000} : false,
      loop:true,
      init: false
    });

    mainBannerSwiper.on('snapIndexChange', function() {
      const speed = mainBannerSwiper.params.speed;

      swiperWrapper.classList.add('swiper-wrapper--move');
      setTimeout(() => swiperWrapper.classList.remove('swiper-wrapper--move'), speed/2)
    });

    window.addEventListener('load', function() {
      mainBannerSwiper.init();
      mainBannerSwiper.updateSize();
    });
  }
}

